exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-charities-js": () => import("./../../../src/pages/charities.js" /* webpackChunkName: "component---src-pages-charities-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-verifylogin-expired-index-js": () => import("./../../../src/pages/verifylogin/expired/index.js" /* webpackChunkName: "component---src-pages-verifylogin-expired-index-js" */),
  "component---src-pages-verifylogin-success-index-js": () => import("./../../../src/pages/verifylogin/success/index.js" /* webpackChunkName: "component---src-pages-verifylogin-success-index-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-templates-author-post-template-js": () => import("./../../../src/templates/AuthorPostTemplate.js" /* webpackChunkName: "component---src-templates-author-post-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-category-post-tempalte-js": () => import("./../../../src/templates/CategoryPostTempalte.js" /* webpackChunkName: "component---src-templates-category-post-tempalte-js" */),
  "component---src-templates-dynamic-pages-js": () => import("./../../../src/templates/DynamicPages.js" /* webpackChunkName: "component---src-templates-dynamic-pages-js" */),
  "component---src-templates-impact-fund-intro-template-js": () => import("./../../../src/templates/ImpactFundIntroTemplate.js" /* webpackChunkName: "component---src-templates-impact-fund-intro-template-js" */)
}

