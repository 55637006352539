import React from "react"

import "@fontsource/spartan/400.css"
import "@fontsource/spartan/600.css"
import "@fontsource/spartan/700.css"
import "@fontsource/spartan/900.css"
import "swiper/css"
import "react-modal-video/css/modal-video.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./src/styles/global.css"
import "./src/styles/datoRenderer.css"
import "./src/styles/charitySection.css"
import "./src/styles/impactFund.css"
import "./src/styles/stripe.css"
import "./src/styles/list-style.css"
import "./src/styles/about-toucan.css"
import "./src/styles/slider-section.css"
import "./src/styles/youtube-modal.css"
import "./src/styles/charity-form.css"
import "./src/styles/charity-page-paragraph.css"
import "./src/styles/donation-total.css"
import "./src/styles/footer.css"
import "./src/styles/nav-menu.css"

import { RecoilRoot } from "recoil"

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>{element}</RecoilRoot>
)
